<template  lang="pug">
    v-container()
        v-row()
            v-col( cols="8" offset="2")
                v-card(outlined)
                    v-card-text
                        v-tabs(v-model="tab")
                            v-tab() Nueva   
                            v-tab() Listado
                           
                        v-tabs-items(v-model="tab")
                            v-tab-item(class="pt-5")
                                v-card(outlined)
                                    v-card-text(class="pt-5")
                                        h1( class="text-center text-h6 blue-500--text") Acortador de Urls
                                        validation-observer(ref="observer" v-slot="{ invalid }")
                                            form(@submit.prevent="submit")
                                                v-row 
                                                    v-col 
                                                        validation-provider(
                                                            v-slot="{ errors }"
                                                            name="url target"
                                                            rules="url|required"
                                                        )
                                                            v-text-field(v-model="form.target" :error-messages="errors" label="Url" required)
                                                v-row 
                                                    v-col
                                                        v-btn(type="submit" small) Acortar

                                    v-card(outlined class="mx-auto ml-1 mr-1 mb-1" v-if="formResponse.target")
                                        v-list-item(two-line)
                                            v-list-item-content
                                                v-list-item-title Url recortada
                                                p(style="color: rgba(0, 0, 0, 0.6);") <b>Url original:</b> {{ formResponse.target }}
                                                p(style="color: rgba(0, 0, 0, 0.6);") <b>Url recortada:</b> {{ formResponse.link }}
                                                v-list-item-subtitle Creado: {{ formResponse.created_at }}

                            v-tab-item(class="pt-5")
                                v-card(outlined class="mx-auto")
                                    v-list-item(two-line v-for="item in links")
                                        v-list-item-content
                                            v-list-item-title {{ item.link }}
                                            p(style="color: rgba(0, 0, 0, 0.6);") {{ item.target }}
                                            v-list-item-subtitle Creado: {{ item.created_at }}

</template>
<script>
import AcortadorUrlService from '../../../services/AcortadorUrlService'
const acortadorUrlService = new AcortadorUrlService();

export default {
    name: 'AcortadorURLS',
    data() {
        return {
            tab: null,
            form: {
                target: '',
            },
            formResponse: {},
            links: [],
            code: 9999
        }
    },
    computed: {
    },
    methods: {
        submit() {
            this.$refs.observer.validate().then( async res=> {
				if(res){
                    let params = {};
                    try {
                        var tarjet = new URL(this.form.target);
                        params["target"] = tarjet.search.length > 0 ? tarjet.href + "&usercode=" + this.code : tarjet.href + "?usercode=" + this.code
                    } catch(error) {
                        console.log(error)
                        return
                    }
                    acortadorUrlService.
                        create(params)
                        .then(response => {
                            console.log(response)
                            this.links.unshift({
                                link: response.link,
                                target: response.target,
                                created_at: response.created_at
                            })
                            this.formResponse = response;
                            
                            this.$refs.observer.reset();
                            // Resetting Values
                            this.form.target = '';
                            // Wait until the models are updated in the UI
                            this.$nextTick(() => {
                                this.$refs.observer.reset();
                            });
                        })
                        .catch(error => {
                            console.log(error)
                        })
				}
			})
        },
        getLinks() {            
            acortadorUrlService.
                getLinks()
                .then(response => {
                    if(response.data){
                        this.links = response.data
                    }
                })
                .catch(error => {
                    console.log(error)
                })    
        }
    },
    mounted() {
        this.getLinks();
    }

}
</script>
